body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ---------------------------------------------------------- */

.navbar {
  position: absolute;
  width: 100%;
}

/* login css */

:root {
  --color-white: #ffffff;
  --color-light: #f8c2b917;
  --color-black: #121212;
  --color-night: #001632;
  --color-red: #ff6b68;
  --color-blue: #1a73e8;
  --color-gray: #80868b;
  --color-grayish: #dadce0;
  --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

a {
  color: var(--color-red);
  text-decoration: none;
}

.login {
  /* background: var(--color-light); */
  background-image: url("./img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.login .container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80rem;
  min-height: 100vh;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
}

.login .text-large {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-black);
}

.login .text-normal {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-black);
}

.login .text-links {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-red);
}

.login .text-links:hover {
  text-decoration: underline;
}

.login .cardWrapper {
  max-width: 28rem;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem 2.5rem;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
}

.login .form {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

.login .input-control {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.login .input-field {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  width: 100%;
  height: auto;
  padding: 0.75rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: var(--color-black);
  background: #b5b5b517;
  text-transform: unset;
  text-rendering: optimizeLegibility;
}

.login .input-submit {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  min-width: 40%;
  height: auto;
  padding: 0.65rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: var(--color-white);
  background: var(--color-red);
  box-shadow: var(--shadow-medium);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
}

.login .striped {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.login .striped-line {
  flex: auto;
  flex-basis: auto;
  border: none;
  outline: none;
  height: 2px;
  background: var(--color-grayish);
}

.login .striped-text {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  color: var(--color-black);
  margin: 0 1rem;
}

.login .method-control {
  margin-bottom: 1rem;
}

.login .method-action {
  font-family: inherit;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0.35rem 1.25rem;
  outline: none;
  border: 2px solid var(--color-grayish);
  border-radius: 2rem;
  color: var(--color-black);
  background: var(--color-white);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease;
}

.login .method-action:hover {
  background: var(--color-light);
}

.c-width-login {
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
}

.logoHeight {
  height: 120px;
}

/* home */

.mainContent {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  background: linear-gradient(-45deg, #ffe8e1, #ffd4e5, #c6f0ff, #cbfff3);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding-top: 76px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mainContent .option-btn {
  font-size: 30px;
  padding: 20px;
  border-radius: 40px;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.mainContent .next-btn {
  font-size: 20px;
  border-radius: 40px;
  font-weight: 600;
}


/* 16 */

.btn-16 {
  border: none;
  color: #000;
}

.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}

.btn-16:hover {
  color: #fff;
  background: #000;
}

.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-16:active {
  top: 2px;
  color: #000;
  background: #fff;
}

.question-card {
  background: #ffffff4d;
  padding: 30px;
  box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%),
    7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%);
}

.modal-img{
  width: 30%;
  margin: 15px auto;
}

.thankyou-text{
  margin: 40px 0;
  text-align: center;
}


@media screen and (max-width: 768px) {
  .mainContent .next-btn {
    font-size: 15px;
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}

